import React, { useEffect } from "react";

import styles from './footer.module.css'
import { ArrowImage, LogoEN, LogoPL, NIFCLogoPLImage } from "../../assets/images/svg";
import { Link, useParams } from "react-router-dom";
import translation from "../../Components/Helpers/translation";
import { minHeight } from "../../Components/Helpers/functions";

function Footer() {

    const { lang } = useParams()

    useEffect(() => {
        minHeight()
    }, [])

    return (
        <footer className={styles.wrapper}>
            <div className={styles.top}>
                <div className={styles.container}>
                    <Link to={`/${lang}`} className={styles.logo}>
                        {lang === 'en' ? (
                            <LogoEN />
                        ) : (

                            <LogoPL />
                        )}
                    </Link>
                    <div className={styles.middle}>
                        {Object.entries(translation.footer.top.siteTitles).map((item, index) => {
                            return (
                                <Link to={`/${lang}/${item[0]}`} key={index} className={styles.link}>
                                    {item[1]}
                                </Link>
                            )
                        })}
                    </div>
                    <div className={styles.right}>
                        <p className={styles.title}>
                            {translation.footer.top.sponsors}
                        </p>
                        <div className={styles.images}>
                            {lang === 'en' ? (
                                <>
                                    <div className={styles.firstImageEn} />
                                    <div className={styles.middleImageEn} />
                                    <div className={styles.lastImageEn} />
                                </>
                            ) : (
                                <>
                                    <div className={styles.firstImage} />
                                    <div className={styles.middleImage}>
                                        <NIFCLogoPLImage />
                                    </div>
                                    <div className={styles.lastImage} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.container}>
                    <button type={"button"} className={styles.arrowGoToTop} onClick={() => window.scroll(0, 0)}>
                        <ArrowImage />
                    </button>
                    <span className={styles.rights}>{translation.footer.bottom.rights}</span>
                    <Link to={`/${lang}/deklaracja-dostepnosci`} className={styles.link}>
                        {translation.footer.bottom.declaration}
                    </Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer