import React from "react";
import {Helmet} from "react-helmet";
import translation from "../Helpers/translation";

function SeoHeader(props) {
    return (
        <Helmet>
            <title>{props.title ? props.title : translation.seo.titles.mainTitle}</title>
            <meta name={"description"} content={props.description ? props.description : translation.seo.description.mainDescription} />
            <meta property={"og:title"} content={props.title ? props.title : translation.seo.titles.mainTitle} />
            <meta property={"og:description"} content={props.description ? props.description : translation.seo.description.mainDescription} />
            <meta property={"og:image"} content={props.image ? props.image : "https://portalmuzykipolskiej.pl/dmp-pl-y.jpg"} />
        </Helmet>
    )
}

export default SeoHeader