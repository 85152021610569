import React, {useEffect} from "react";

import styles from './pdfButton.module.css'
import {PdfWrapperImage} from "../../assets/images/svg";
import translation from "../Helpers/translation";

function PdfButton({filename , siteName}) {

    useEffect(() => {

        const changeTitle = (event) => {
            if (event.type === "beforeprint") {
                document.title = filename
            } else {
                document.title = siteName
            }
        }

        window.addEventListener("beforeprint", changeTitle);
        window.addEventListener("afterprint", changeTitle);
        return () => {
            window.removeEventListener("beforeprint", changeTitle)
            window.addEventListener("afterprint", changeTitle);
        }
    },[filename, siteName])

    return (
        <button type={"button"} className={styles.button} onClick={() => window.print()}>
            <span className={styles.buttonContent}>
                <span className={styles.mainImage}>
                    <PdfWrapperImage/>
                </span>
                <span className={styles.texts}>
                    <span className={styles.topText}>{translation.all.pdf.download}</span>
                    <span className={styles.bottomText}>{translation.all.pdf.file}</span>
                </span>
            </span>
        </button>
    )
}

export default PdfButton