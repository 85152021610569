import React, { useEffect, useState } from "react";

import styles from './articlesListPage.module.css'
import { useLocation, useParams } from "react-router-dom";
import { apiUrls } from "../../assets/api/apiURL";
import { LoaderPage } from "../../Components/LoaderPage/loaderPage";
import Footer from "../Footer/footer";
import SearchInput from "../../Components/Inputs/SearchInput/searchInput";
import translation from "../../Components/Helpers/translation";
import queryString from "query-string";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";
import MobileFiltersWrapper from "../../Components/MobileFiltersWrapper/mobileFiltersWrapper";
import ArticleItem from "../../Components/ListItems/ArticleItem/articleItem";
import SelectInput from "../../Components/Inputs/SelectInput/selectInput";
import { createParamsLink } from "../../Components/Helpers/functions";
import SeoHeader from "../../Components/SeoHeader/seoHeader";

function TopFilters(props) {

    return (
        <div className={styles.top}>
            <div className={styles.searchWrapper}>
                <p className={styles.searchTitle}>{translation.all.search}</p>
                <div className={styles.searchItem}>
                    <SearchInput placeholder={translation.all.searchPlaceholder} prevValue={props.queryParams['search']} />
                </div>
            </div>
            <div className={styles.inputWrapper}>
                <SelectInput search={true} data={translation.personPage.articles.mobileSortData} />
            </div>
        </div>
    )
}

function ArticlesListPage() {

    let { lang, id } = useParams()

    const location = useLocation()
    const queryParams = queryString.parse(location.search, { arrayFormat: 'comma' })

    const size = useWindowSize()

    const limit = 20

    const [data, setData] = useState(null)
    const [counter, setCounter] = useState(0)
    const [noMore, setNoMore] = useState(false)
    const [loading, setLoading] = useState(false)

    id = parseInt(id)

    const showMoreHandle = () => {
        setLoading(true)
        setCounter(counter + 1)

        if (queryParams.sort) {
            const tmpItem = translation.personPage.articles.sortData.find((item) => parseInt(item.valueASC) === parseInt(queryParams.sort) || parseInt(item.valueDESC) === parseInt(queryParams.sort))

            if (parseInt(tmpItem.valueASC) === parseInt(queryParams.sort)) {
                queryParams.sort = tmpItem.queryNameASC
            } else {
                queryParams.sort = tmpItem.queryNameDESC
            }
        }

        const searchParams = createParamsLink(queryParams, '&')

        fetch(`${apiUrls.mainUrl}${apiUrls.composer.articles}?lang=${lang}&offset=${limit * (counter + 1)}&limit=${limit}${searchParams}`)
            .then((res) => res.json())
            .then((json) => {
                setData((prev) => {
                    if (prev !== null) {
                        return [...prev, ...json]
                    } else {
                        return json
                    }
                })
                if (json.length < limit) {
                    setNoMore(true);
                }
                setTimeout(() => {
                    setLoading(false)
                },100)
            })
    }


    useEffect(() => {
        setData(null)

        let query = queryString.parse(location.search, { arrayFormat: 'comma' })

        if (query.sort) {
            const tmpItem = translation.personPage.articles.sortData.find((item) => parseInt(item.valueASC) === parseInt(query.sort) || parseInt(item.valueDESC) === parseInt(query.sort))

            if (parseInt(tmpItem.valueASC) === parseInt(query.sort)) {
                query.sort = tmpItem.queryNameASC
            } else {
                query.sort = tmpItem.queryNameDESC
            }
        }

        const searchParams = createParamsLink(query, '&')

        fetch(`${apiUrls.mainUrl}${apiUrls.composer.articles}?lang=${lang}&offset=${0}&limit=${limit}${searchParams}`)
            .then((res) => res.json())
            .then((json) => {
                setData((prev) => {
                    if (prev !== null) {
                        return [...prev, ...json]
                    } else {
                        return json
                    }
                })
                if (json.length < limit) {
                    setNoMore(true);
                }
            })
    }, [lang, id, location, limit]);

    return (
        <>
            <SeoHeader
                title={`${translation.seo.titles.siteTitle.articles} - ${translation.seo.titles.mainTitle}`}
                description={`${translation.seo.description.mainDescription}`}
            />
            <LoaderPage data={data !== null} />
            {data !== null ?
                <div className={styles.wrapper}>
                    <main>
                        <div className={styles.container}>
                            {size.width > 650
                                ? <TopFilters queryParams={queryParams} />
                                : <MobileFiltersWrapper>
                                    <TopFilters queryParams={queryParams} />
                                </MobileFiltersWrapper>
                            }
                            <div className={`${styles.listWrapper} ${loading ? styles.blur : ''}`}>
                                {data.map((item, index) => {
                                    return (
                                        <ArticleItem item={item} lang={lang} key={index} styles={styles.itemWrapper} />
                                    )
                                })}
                            </div>
                            {!noMore ?
                                <button className={styles.showMoreButton} onClick={showMoreHandle}>
                                    {translation.newsPage.showMore}
                                </button>
                                : null
                            }
                        </div>
                    </main>
                    <Footer />
                </div>
                : null
            }
        </>
    )
}

export default ArticlesListPage