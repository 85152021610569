import React, {useEffect, useRef} from 'react';
import styles from './loaderPage.module.css'
import './loaderPage.module.css'
import {LoaderSVGOne, LoaderSVGTwo} from "../../assets/images/svg";

export function LoaderPage(props) {

    const loaderRef = useRef()

    useEffect(() => {

        if(props.data && loaderRef.current) {
            loaderRef.current.style.transition = "0.3s ease-in-out"
            if(props.scroll !== false) {
                window.scrollTo(0,0)
            }
            setTimeout(() => {
                loaderRef.current.style.opacity = "0"
                loaderRef.current.style.visibility = "hidden"
            },1)
        } else {
            loaderRef.current.style.transition = "0s all"
            loaderRef.current.style.opacity = "1"
            loaderRef.current.style.visibility = "visible"
        }

    },[props.data, props.scroll])

    return (
        <div className={styles.loader} ref={loaderRef}>
            <div className={`${styles.loaderInner} ${props.builder ? styles.loaderInnerRotate : ''}`}>
                <div className={`${styles.circle} ${styles.one}`}>
                    <LoaderSVGOne/>
                </div>
                <div className={`${styles.circle} ${styles.two}`}>
                    <LoaderSVGTwo/>
                </div>
            </div>
        </div>
    );
}