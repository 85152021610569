import React, {useEffect, useState} from "react";
import {LoaderPage} from "../../Components/LoaderPage/loaderPage";
import styles from "./declarationPage.module.css";
import {apiUrls} from "../../assets/api/apiURL";
import {useParams} from "react-router-dom";
import Footer from "../Footer/footer";
import translation from "../../Components/Helpers/translation";
import SeoHeader from "../../Components/SeoHeader/seoHeader";

function DeclarationPage() {

    const { lang } = useParams()

    const [data, setData] = useState(null)

    useEffect(() => {
        setData(null)

        fetch(`${apiUrls.mainUrl}${apiUrls.page}/1?lang=${lang}`)
            .then((res) => res.json())
            .then((json) => {
                setTimeout(() =>{
                    setData(json)
                },200)
            })
    }, [lang]);

    return (
        <>
            <SeoHeader
                title={`${translation.seo.titles.siteTitle.accessibility} - ${translation.seo.titles.mainTitle}`}
                description={`${translation.seo.description.mainDescription}`}
            />
            <LoaderPage data={data !== null}/>
            {data !== null ?
                <div className={styles.wrapper}>
                    <main>
                        <div className={styles.container} dangerouslySetInnerHTML={{__html: data.txt}}/>
                    </main>
                    <Footer/>
                </div>
                : null
            }
        </>
    )
}

export default DeclarationPage