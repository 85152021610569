import React, {useEffect, useState} from "react";

import styles from './aboutPage.module.css'
import {LoaderPage} from "../../Components/LoaderPage/loaderPage";
import {useParams} from "react-router-dom";
import {apiUrls} from "../../assets/api/apiURL";
import Footer from "../Footer/footer";
import {LogoNoText} from "../../assets/images/svg";
import translation from "../../Components/Helpers/translation";
import SeoHeader from "../../Components/SeoHeader/seoHeader";

function AboutPage() {

    const { lang } = useParams()

    const [data, setData] = useState(null)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        setData(null)

        fetch(`${apiUrls.mainUrl}${apiUrls.page}/4?lang=${lang}`)
            .then((res) => res.json())
            .then((json) => {
                setTimeout(() =>{
                    setData(json)
                    setTimeout(() => {
                        setLoaded(true)
                        setTimeout(() => {
                            setLoaded(false)
                        },600)
                    },400)
                },200)
            })
    }, [lang]);

    return (
        <>
            <SeoHeader
                title={`${translation.seo.titles.siteTitle.about} - ${translation.seo.titles.mainTitle}`}
                description={`${translation.seo.description.mainDescription}`}
            />
            <LoaderPage data={data !== null}/>
            {data !== null ?
                <div className={styles.wrapper}>
                    <main>
                        <div className={`${styles.topWrapper} ${loaded ? styles.active : ''}`}>
                            <div className={styles.absoluteWrapper}>
                                <div className={`${styles.image} ${styles.composer1}`}/>
                                <div className={`${styles.image} ${styles.composer2}`}/>
                                <div className={`${styles.image} ${styles.composer3}`}/>
                                <div className={`${styles.image} ${styles.composer4}`}/>
                                <div className={`${styles.image} ${styles.composer5}`}/>
                                <div className={`${styles.image} ${styles.composer6}`}/>
                                <div className={`${styles.image} ${styles.composer7}`}/>
                                <div className={`${styles.image} ${styles.composer8}`}/>
                                <div className={`${styles.image} ${styles.composer9}`}/>
                                <div className={`${styles.image} ${styles.composer10}`}/>
                                <div className={`${styles.image} ${styles.composer11}`}/>
                                <div className={`${styles.image} ${styles.composer12}`}/>
                                <div className={`${styles.image} ${styles.composer13}`}/>
                                <div className={`${styles.image} ${styles.composer14}`}/>
                                <div className={`${styles.image} ${styles.composer15}`}/>
                                <div className={`${styles.image} ${styles.composer16}`}/>
                            </div>
                        </div>
                        <div className={styles.container}>
                            <div className={styles.topTitleWrapper}>
                                <LogoNoText/>
                                <h2 className={styles.topTitle}>
                                    {data.title}
                                </h2>
                            </div>
                            <div className={`${styles.content} ${lang === "en" ? styles.contentEN : ""}`} dangerouslySetInnerHTML={{__html : data.txt}}/>
                        </div>
                    </main>
                    <Footer/>
                </div>
                : null
            }
        </>
    )
}

export default AboutPage