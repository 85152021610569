import React from "react";

import styles from './socialShare.module.css'
import {Facebook, Linkedin, Twitter} from "../../assets/images/svg";

function SocialShare(props) {

    const shareHandler = (type) => {
        let popUpObj
        if(type === 1) {
            popUpObj = window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,"ModalPopUp","width=600, height=600");
        } else if(type === 2) {
            popUpObj = window.open(`https://twitter.com/intent/tweet?url=${window.location.href}`,"ModalPopUp","width=600, height=600");
        } else if(type === 3) {
            popUpObj = window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,"ModalPopUp","width=600, height=600");
        }
        popUpObj.focus();
    }

    return (
        <div className={`${styles.wrapper} ${props.vertical ? styles.vertical : '' }`}>
            <button type={"button"} className={styles.shareButton} onClick={() => shareHandler(1)}>
                <Facebook />
            </button>
            <button type={"button"} className={styles.shareButton} onClick={() => shareHandler(1)}>
                <Twitter />
            </button>
            <button type={"button"} className={styles.shareButton}onClick={() => shareHandler(1)}>
                <Linkedin />
            </button>
        </div>
    )
}

export default SocialShare