import React, {useRef, useState} from "react";

import styles from './mobileFiltersWrapper.module.css'
import SlideUpDown from "../Helpers/slideUpDown/slideUpDown";
import translation from "../Helpers/translation";
import {ArrowImage} from "../../assets/images/svg";
import changeText from "../Helpers/changeText";


function MobileFiltersWrapper(props) {

    const textRef = useRef()
    const [closed, setClosed] = useState(true)

    const openCloseHandle = () => {
        setClosed(!closed)
        changeText(textRef.current, styles.textFade, `${!closed ? translation.all.showFilters : translation.all.closeFilters}`)
    }

    return (
        <div className={styles.wrapper}>
            <button type={"button"} className={styles.button} onClick={openCloseHandle}>
                <div className={styles.buttonContent}>
                    <span className={`${styles.text} ${styles.textFade}`} ref={textRef}>{translation.all.showFilters}</span>
                    <div className={`${styles.arrow} ${!closed ? styles.rotate : ''}`}>
                        <ArrowImage/>
                    </div>
                </div>
            </button>
            <SlideUpDown closed={closed} closedStart={true}>
                <div className={styles.content}>
                    {props.children}
                </div>
            </SlideUpDown>
        </div>
    )
}

export default MobileFiltersWrapper