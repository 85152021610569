function toggleTransitionWithTimeout(container, containerClass , nextText) {
    container.classList.remove(containerClass);
    setTimeout(() => {
        requestAnimationFrame(() => {
            container.innerHTML = nextText;
            container.classList.add(containerClass);
        });
    }, 200); // timeout
}

export default toggleTransitionWithTimeout