import React, {useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";

import styles from './selectInput.module.css'

import queryString from "query-string";
import SlideUpDown from "../../Helpers/slideUpDown/slideUpDown";
import translation from "../../Helpers/translation";
import {ArrowImage} from "../../../assets/images/svg";
import {multipleParamsSearchHandle, searchHandle} from "../../Helpers/functions";
import useOnclickOutside from "react-cool-onclickoutside";
import slug from "slug";

function SelectInput(props) {

    const { lang } = useParams()

    const location = useLocation()
    const history = useHistory();
    const queryParams = queryString.parse(location.search)
    const [closed, setClosed] = useState(true);

    const setHandle = (item) => {
        if(props.custom) {
            const params = [
                {name: "min", value: item.min, type: "string"},
                {name: "max", value: item.max, type: "string"},
            ]
            multipleParamsSearchHandle(params, location, history, queryParams)
        } else {
            if(props.search) {
                searchHandle(props.type ? props.type : 'sort', item.id, "string", location, history, queryParams);
            } else if (props.reload) {
                history.push(`/${lang}/osoba/${item.id}-${slug(item.name)}/os-czasu-kalendarium`)
            } else {
                props.setSelected(item.id)
            }
        }

        setClosed(true)
    }

    const findItem = (items) => {
        if(props.custom) {
            return items.find((item) => {
                if (props.selected.min > 0 && props.selected.max > 0) {
                    return props.selected.min === item.min && props.selected.max === item.max
                } else {
                    return false
                }
            })
        } else {
            return items.find((item) => {
                if (typeof item.id === 'number') {
                    return  parseInt(item.id) === parseInt(props.selected)
                } else {
                    return item.id === props.selected
                }
            })
        }
    }

    return (
        <div className={`${styles.container} ${props.light ? styles.light : ''}`} ref={useOnclickOutside(() => setClosed(true))}>
            {props.custom
                ? <button className={`${styles.openSort} ${closed ? styles.closed : ''} ${styles.active}`} onClick={() => setClosed(!closed)}>
                    <span className={styles.label}>
                        <span className={styles.value}>
                            {findItem(props.data)
                                ? findItem(props.data).name
                                : `${props.selected.min}–${props.selected.max}`
                            }
                        </span>
                        <div className={styles.arrow}>
                            <ArrowImage/>
                        </div>
                    </span>
                </button>
                : <button className={`${styles.openSort} ${closed ? styles.closed : ''} ${props.search ? props.data.find((item) => parseInt(item.id) === parseInt(queryParams[props.type ? props.type : "sort"])) ? styles.active : '' : props.selected ? styles.active : '' }`} onClick={() => setClosed(!closed)}>
                    <span className={styles.label}>
                        {props.search
                            ? <span className={styles.pre}>{props.placeholder ? props.placeholder : translation.all.sort}</span>
                            : null
                        }
                        <span className={styles.value}>
                            {props.search
                                ? parseInt(queryParams[props.type ? props.type : "sort"])
                                    ? props.data.find((item) => parseInt(item.id) === parseInt(queryParams[props.type ? props.type : "sort"])).name
                                    : props.data[0].name
                                : props.selected
                                    ? findItem(props.data).name
                                    : props.placeholder
                            }
                        </span>
                        <div className={styles.arrow}>
                            <ArrowImage/>
                        </div>
                    </span>
                </button>
            }
            <div className={styles.sortOptionsContainer}>
                <SlideUpDown closed={closed} closedStart={true}>
                    <div className={styles.sortOptionsWrapper}>
                        {props.data.map((item,index) => {
                            return (
                                <button key={index} type={"button"} className={styles.option} onClick={() => setHandle(item)}>
                                    <span className={styles.label}>
                                        {props.search
                                            ? <span className={styles.pre}>{props.placeholder ? '' : translation.all.sort}</span>
                                            : null
                                        }
                                        <span className={styles.value}>
                                            {/* {item.name ? item.name : item} */}
                                            {item.name}
                                        </span>
                                    </span>
                                </button>
                            )
                        })}
                    </div>
                </SlideUpDown>
            </div>
        </div>
    )
}

export default React.memo(SelectInput)