export const apiUrls = {
    mainUrl: "https://api-pmp.nifc.pl/v2/index.php/",
    mapData: "people_homepage",
    historyContext : "calendar/events/get_historical_context",
    page: "page",
    news: "news",
    people: "people",
    bibliographies: "bibliographies",
    article: "article",
    composers: "bibliographies/characters",
    authors: "bibliographies/authors",
    categories: "bibliographies/categories",
    languages: "bibliographies/languages",
    years: "bibliographies/years",
    compositions: {
        main: "compositions",
        composers: "/composers",
        keys: "/keys",
        cast: "/cast",
        genres: "/genres",
        genre: "/genre",
        years: "/years",
        opuses: "/opuses",
        dedications: "/dedications"
    },
    manuscripts: {
        categories: "manuscripts/categories",
        places_of_storage: "manuscripts/places_of_storage",
        compositions: "manuscripts/compositions"
    },
    articles: {
        title: "articles/title",
        author: "articles/author"
    },
    letters: {
        main: "letters",
        titles: "/titles",
        senders: "/senders",
        addressees: "/addressees",
        years: "/years",
        composers: "/composers",

        keys: "/keys",
        cast: "/cast",
        genres: "/genres",
        genre: "/genre",
        
        opuses: "/opuses",
        dedications: "/dedications"
    },
    composer: {
        person: "person",
        people: "people",
        compositions: "compositions",
        composition: "composition",
        bibliographies: "bibliographies",
        bibliography: "bibliography",
        manuscripts: "manuscripts",
        manuscript: "manuscript",
        multimedia: "multimedia",
        articles: "articles",
        spotify: "spotify",
        letters: "letters",
        letter: "letter"
    }
};