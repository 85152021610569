import React from "react";

import styles from './pdfPage.module.css'
import { useParams } from "react-router-dom";
import { LogoEN, LogoPL, NIFCLogoPLImage } from "../../assets/images/svg";
import Mkidn from '../../assets/images/general/pdfMkid.png'
import Dmp from '../../assets/images/general/pdfDmp.png'
import MkidnEN from '../../assets/images/EN/logoMkidnEN.png'

function PdfPage(props) {

    const { lang } = useParams()

    return (
        <div className={styles.print}>
            <div className={styles.pageHeader}>
                <div className={styles.image}>
                    {lang === 'en' ? (
                        <LogoEN />
                    ) : (

                        <LogoPL />
                    )}
                </div>
                <a href={'https://portalmuzykipolskiej.pl/pl'} className={styles.link}>www.portalmuzykipolskiej.pl</a>
            </div>
            <div className={styles.pageFooter}>
                <div className={styles.images}>
                    {lang === 'en' ? (
                        <>
                            <img src={MkidnEN} className={styles.firstImage} alt={''} />
                            <div className={styles.middleImageEN} />
                            <img src={Dmp} className={styles.lastImage} alt={''} />
                        </>
                    ) : (
                        <>
                            <img src={Mkidn} className={styles.firstImage} alt={''} />
                            <div className={styles.middleImage}>
                                <NIFCLogoPLImage />
                            </div>
                            <img src={Dmp} className={styles.lastImage} alt={''} />
                        </>
                    )}
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <td>
                            <div className={styles.pageHeaderSpace}></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {props.children}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div className={styles.pageFooterSpace}></div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default PdfPage