import React, { useEffect, useState } from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import '@splidejs/react-splide/css/core';

import styles from './articlePage.module.scss'
import { LoaderPage } from "../../Components/LoaderPage/loaderPage";
import { apiUrls } from "../../assets/api/apiURL";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Footer from "../Footer/footer";
import { ArrowRedImage } from "../../assets/images/svg";
import translation from "../../Components/Helpers/translation";
import PdfButton from "../../Components/PdfButton/pdfButton";
import PdfPage from "../../Components/PdfPage/pdfPage";
import SocialShare from "../../Components/SocialShare/socialShare";
import ArticleItem from "../../Components/ListItems/ArticleItem/articleItem";
import SeoHeader from "../../Components/SeoHeader/seoHeader";

function ArticlePage() {

    let { lang, id } = useParams()

    const history = useHistory()
    const location = useLocation()
    const [data, setData] = useState(null)
    const [worthToSee, setWorthToSee] = useState(null)
    
    id = parseInt(id)

    const goBackHandle = () => {
        if (location.state) {
            history.push(location.state.from)
        } else {
            history.goBack()
        }
    }

    useEffect(() => {
        setData(null)

        fetch(`${apiUrls.mainUrl}${apiUrls.article}/${id}?lang=${lang}`)
            .then((res) => res.json())
            .then((json) => {
                setData(json)
            })
    }, [lang, id]);

    useEffect(() => {
        fetch(`${apiUrls.mainUrl}${apiUrls.composer.articles}?lang=${lang}&offset=${0}&limit=${20}`)
            .then((res) => res.json())
            .then((json) => {
                setWorthToSee(json)
            })
    }, [lang]);

    return (
        <>
            <LoaderPage data={data !== null && worthToSee !== null} />
            {data !== null && worthToSee !== null ?
                <div className={styles.wrapper}>
                    <SeoHeader
                        title={`${translation.seo.titles.siteTitle.article} "${data.title}" - ${translation.seo.titles.mainTitle}`}
                        description={`${translation.seo.description.mainDescription}`}
                    />
                    <main>
                        <div className={styles.container}>
                            <button type={"button"} className={styles.button} onClick={goBackHandle}>
                                <div className={styles.arrow}>
                                    <div className={styles.arrowWrapper}>
                                        <ArrowRedImage />
                                        <ArrowRedImage />
                                    </div>
                                </div>
                                <span>
                                    {translation.all.goBack}
                                </span>
                            </button>
                            <div className={styles.socialWrapper}>
                                <SocialShare />
                            </div>
                            <PdfPage>
                                {data.image
                                    ? <img src={data.image} className={styles.image} alt={data.alt_image ? data.alt_image : ''} />
                                    : null
                                }
                                <h2 className={styles.title}>{data.title}</h2>
                                <p className={styles.authors}>{data.authors.map(author => `${author.first_name} ${author.last_name}`).join(', ')}</p>
                                <div className={styles.textWrapper} dangerouslySetInnerHTML={{ __html: data.txt }} />
                            </PdfPage>
                            <div className={styles.buttonWrapper}>
                                <PdfButton
                                    filename={`${translation.seo.titles.mainTitle} - ${translation.seo.titles.article} - "${data.title}"`}
                                    siteName={`${translation.seo.titles.siteTitle.article} "${data.title}" - ${translation.seo.titles.mainTitle}`}
                                />
                            </div>
                        </div>

                        <Splide
                            hasTrack={false}
                            aria-label="..."
                            className={styles.carousel}
                            data-slided={false}
                            options={{
                                type: 'loop',
                                autoplay: false,
                                interval: 3000,
                                pagination: false,
                                speed: 350,
                                easing: 'ease-in-out',
                                updateOnMove: true,
                                perMove: 1,
                                gap: 22,
                                perPage: 2,
                                breakpoints: {
                                    700: {
                                        perPage: 1,
                                    },
                                },
                            }}
                        >
                            <div className={styles.carouselRow}>
                                <h2 className={styles.sliderTitle}>{translation.all.worthToSee}</h2>
                                <div className={`splide__arrows ${styles.arrows}`}>
                                    <button className={`splide__arrow splide__arrow--prev ${styles.arrow} ${styles.leftArrow}`}>
                                        <div className={styles.arrowWrapper}>
                                            <ArrowRedImage />
                                            <ArrowRedImage />
                                        </div>
                                    </button>
                                    <button className={`splide__arrow splide__arrow--next ${styles.arrow} ${styles.rightArrow}`}>
                                        <div className={styles.arrowWrapper}>
                                            <ArrowRedImage />
                                            <ArrowRedImage />
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <SplideTrack className={styles.track}>
                                {worthToSee.map((item, index) => {
                                    return (
                                        <SplideSlide key={index} className={styles.slide}>
                                            <ArticleItem item={item} lang={lang} />
                                        </SplideSlide>
                                    )
                                })}
                            </SplideTrack>
                        </Splide>
                    </main>
                    <Footer />
                </div>
                : null
            }
        </>
    )
}

export default ArticlePage