import React, {useEffect, useState} from "react";

import styles from './searchInput.module.css'
import {MagnifierImage} from "../../../assets/images/svg";
import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import {searchHandle} from "../../Helpers/functions";

function SearchInput(props) {

    const history = useHistory()
    const location = useLocation()
    const queryParams = queryString.parse(location.search,{arrayFormat: 'comma'})
    const [inputText, setInputText] = useState(props.prevValue ? props.prevValue : '')
    const [focus, setFocus] = useState(false)

    useEffect(() => {
        if (props.prevValue) {
            setInputText(props.prevValue)
        } else {
            setInputText('')
        }
    },[props.prevValue])

    return (
        <label className={`${styles.wrapper} ${props.setValue === undefined ? focus ? styles.focus : '' : ''} ${inputText ? styles.active : ''}`}>
            <input
                className={styles.searchInput}
                type={"text"}
                placeholder={props.placeholder}
                value={inputText}
                onChange={event => {
                    setInputText(event.target.value);
                    if(props.setValue !== undefined) {
                        props.setValue(event.target.value)
                    }
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onKeyDown={(event) => {
                    if(props.setValue === undefined) {
                        if(event.key === "Enter") {
                            searchHandle("search", inputText, "string", location, history, queryParams)
                        }
                    } else {
                        props.setValue(inputText)
                    }
                }}
            />
            {props.setValue === undefined ?
                <button type={"button"} className={styles.searchButton} onClick={() => searchHandle("search", inputText, "string", location, history, queryParams)}>
                    <MagnifierImage/>
                </button>
                : null
            }
        </label>
    )
}

export default SearchInput