export const orphans = () => {
    document.querySelectorAll("span, h2, h3, h1, p").forEach((span) => {
        let tekst = span.innerHTML;
        tekst = tekst.replace(/(\s)([^<,]{1,2})[\s]+/g, "$1$2&nbsp;");
        span.innerHTML = tekst;
    });
}

export const orphansDirect = (tekst) => {
    if (tekst) {

        tekst = tekst.toString().replace(/^([^<,]{2})[\s]+/g, "$1&nbsp;");
        tekst = tekst.toString().replace(/^([^<,]{1})[\s]+/g, "$1&nbsp;");
        tekst = tekst.toString().replace(/([^<,]{1})[\s]+/g, "$1&nbsp;");
        return tekst
    } else {
        return ''
    }
}

export function minHeight() {
    const headerH = document.getElementsByTagName("header")[0].offsetHeight;
    const footerH = document.getElementsByTagName("footer")[0].offsetHeight;
    const takenH = headerH + footerH;

    if (document.getElementsByTagName("main")[0]) {
        document.getElementsByTagName("main")[0].style.minHeight = `calc(100vh - ${takenH}px)`;
    }
}

export const formatDate = (data) => {
    data = data.split("-")
    data = `${data[2]}.${data[1]}.${data[0]}`
    return data
}

export const formatDateComplex = (data) => {
    data = new Date(data)
    data = `${data.getDate()}.${data.getMonth() < 9 ? `0${data.getMonth() + 1}` : data.getMonth() + 1}.${data.getFullYear()}`
    return data
}

export const getYear = (data) => {
    data = new Date(data)
    data = `${data.getFullYear()}`
    return data
}

export const formatDateComplexYearMonthDay = (data) => {
    data = new Date(data)
    data = `${data.getFullYear()}-${data.getMonth() < 9 ? `0${data.getMonth() + 1}` : data.getMonth() + 1}-${data.getDate() < 10 ? `0${data.getDate()}` : data.getDate()}`
    return data
}

export const formatPhone = (data) => {
    if( data) {
        data = data.replace(/[() ]/g, "");
    }
    return data
}

export const adjustQueryParams = (name, value, type, queryParams) => {
    if (!value) {
        delete queryParams[name]
    } else {
        if (type === "string") {
            queryParams[name] = value
        } else if (type === "multiSelect") {
            if (value.length > 0) {
                queryParams[name] = value.toString()
            } else {
                delete queryParams[name]
            }
        } else {
            if (Array.isArray(queryParams[name])) {
                if (queryParams[name].includes(value)) {
                    queryParams[name].splice(queryParams[name].indexOf(value), 1)
                } else {
                    queryParams[name] = [...new Set([...queryParams[name], ...[value]])]
                }
            } else {
                if (queryParams[name] === value) {
                    queryParams[name] = ''
                } else {
                    queryParams[name] = queryParams[name] ? [queryParams[name], value] : [value]
                }
            }
        }
    }

    return queryParams
}

export const createLink = (params, start, discard) => {
    let link = start ? start : '?'

    discard = discard ? discard : []

    Object.keys(params).forEach(function (key, index) {

        if (!discard.includes(key)) {
            if (params[key]) {
                if (index !== 0) {
                    link = link + '&'
                }

                if (Array.isArray(params[key])) {
                    link = link + `${key}=${params[key].toString()}`
                } else {
                    link = link + `${key}=${params[key]}`
                }
            }
        }
    });

    return link
}

export const createParamsLink = (params, start, discard) => {
    let link = start ? start : '?'

    discard = discard ? discard : []

    Object.keys(params).forEach(function (key, index) {

        if (!discard.includes(key)) {
            if (params[key]) {
                if (index !== 0) {
                    link = link + '&'
                }

                if (Array.isArray(params[key])) {
                    params[key].forEach((item, index) => {
                        if (index !== 0) {
                            link = link + '&'
                        }

                        link = link + `${key}=${item}`
                    })
                } else {
                    link = link + `${key}=${params[key]}`
                }
            }
        }
    });

    return link
}

export const searchHandle = (name, value, type, location, history ,queryParams) => {
    const params = adjustQueryParams(name, value, type, queryParams)
    const link = createLink(params)

    history.push(location.pathname.slice(-1) !== '/' ? `${location.pathname}${link}` : `${location.pathname.slice(0, -1)}${link}`)
}

export const multipleParamsSearchHandle = (values, location, history, queryParams) => {

    let params = ''

    values.forEach((item) => {
        params = adjustQueryParams(item.name, item.value, item.type, queryParams)
    })

    const link = createLink(params)

    history.push(location.pathname.slice(-1) !== '/' ? `${location.pathname}${link}` : `${location.pathname.slice(0, -1)}${link}`)
}

export const sortAsc = (a, b) => {
    const ValueA = a.toLowerCase();
    const ValueB = b.toLowerCase();

    return ValueA.localeCompare(ValueB)
}

export const sortDesc = (a, b) => {
    const ValueA = a.toLowerCase();
    const ValueB = b.toLowerCase();

    return ValueB.localeCompare(ValueA)
}

export const getData = async (urlList) => {
    return await Promise.all(
        urlList.map((item) => fetch(item.url).then(res => res.json().then(json => {
            return {
                response : json,
                name : item.name
            }
        })))
    )
}

export const stripHtml = (string) => {
    let div = document.createElement("div");
    div.innerHTML = string
    string = div.innerText
    return string
}