import React from "react";
import { Link, useParams } from "react-router-dom";
import slug from "slug";
import { stripHtml } from "../../Helpers/functions";
import styles from "./articleItem.module.css";
import translation from "../../Helpers/translation";

const ParentElement = ({ item, index, lang, children, noComposer }) => {

    const noComposerLink = noComposer ? styles.noComposerLink : '';

    return (
        item.id_composer !== 1
            ? <Link to={`/${lang}/artykuly/${item.id}-${slug(stripHtml(item.title))}`} key={index} className={`${styles.link} ${index % 2 !== 0 ? styles.dark : ''} ${noComposerLink} `}>
                {children}
            </Link>
            : <a href={`https://chopin.nifc.pl/${lang}/chopin/rekopis/${item.id}-${slug(stripHtml(item.title))}`} target={"_blank"} rel={"noreferrer"} key={index} className={`${styles.link} ${index % 2 !== 0 ? styles.dark : ''}`}>
                {children}
            </a>
    )
}

function ArticleItem({ item, index, noComposer }) {

    const { lang } = useParams()

    return (
        <ParentElement item={item} index={index} lang={lang} noComposer={noComposer}>
            {!noComposer && (
                <div className={`${styles.itemWrapper} ${styles.first}`}>
                    <div className={styles.imageBackground}>
                        {item.image ?
                            <div className={styles.image} style={{ backgroundImage: `url("${item.image}")` }} />
                            : <div className={styles.imagePlaceHolder}>
                                <p>Brak Zdjęcia</p>
                            </div>
                        }
                    </div>
                    <span className={styles.itemText}>{item.composer}</span>
                </div>
            )}

            <div className={`${styles.itemWrapper} ${styles.bold}`}>
                <span className={`${styles.itemText} ${styles.bold}`}>{item.title}</span>
            </div>
            <div className={`${styles.itemWrapper} ${styles.last}`}>
                {noComposer && (
                    <span className={styles.label}>{translation.personPage.articles.sortData[1].name}</span>
                )}
                <span className={styles.itemText}>{item.authors[0].first_name} {item.authors[0].last_name}</span>
            </div>
        </ParentElement>
    )
}

export default React.memo(ArticleItem)