import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { LoaderPage } from "../../Components/LoaderPage/loaderPage";
import AboutPage from "../AboutPage/aboutPage";
import DeclarationPage from "../DeclarationPage/declarationPage";
import ArticlesListPage from '../ArticlesListPage/articlesListPage';
import ArticlePage from '../ArticlePage/articlePage';

const Header = lazy(() => import('../Header/header'));
const MainPage = lazy(() => import("../MainPage/mainPage"));
const NewsListPage = lazy(() => import("../NewsListPage/newsListPage"));
const NewsPage = lazy(() => import("../NewsPage/newsPage"));
const ComposerListPage = lazy(() => import("../ComposerListPage/composerListPage"));
const ComposerPage = lazy(() => import("../ComposerPage/composerPage"));
const BibliographiesListPage = lazy(() => import("../BibliographiesListPage/bibliographiesListPage"));
const MultimediaListPage = lazy(() => import("../MultimediaListPage/multimediaListPage"));
const CompositionsListPage = lazy(() => import("../CompositionsListPage/compositionsListPage"));
const GenresListPage = lazy(() => import("../GenresListPage/genresListPage"));
const GenresPage = lazy(() => import("../GenresPage/genresPage"));
const SearchPage = lazy(() => import("../SearchPage/searchPage"));

const App = ({ match, location }) => {
    
    return (
        <Suspense fallback={<LoaderPage builder={true} />}>
            <Switch>
                <Route exact path='/:lang(pl|en)'>
                    <Header />
                    <MainPage />
                </Route>

                <Route exact path='/:lang(pl|en)/wyszukiwarka'>
                    <Header />
                    <SearchPage />
                </Route>

                <Route exact path='/:lang(pl|en)/aktualnosci'>
                    <Header />
                    <NewsListPage />
                </Route>

                <Route exact path='/:lang(pl|en)/aktualnosci/:category/:id'>
                    <Header />
                    <NewsPage />
                </Route>

                <Route exact path='/:lang(pl|en)/kompozytorzy/'>
                    <Header />
                    <ComposerListPage />
                </Route>

                <Route exact path='/:lang(pl|en)/bibliografie/'>
                    <Header />
                    <BibliographiesListPage />
                </Route>

                <Route exact path='/:lang(pl|en)/multimedia/'>
                    <Header />
                    <MultimediaListPage />
                </Route>

                <Route exact path='/:lang(pl|en)/kompozycje/'>
                    <Header />
                    <CompositionsListPage />
                </Route>

                <Route exact path='/:lang(pl|en)/gatunki/'>
                    <Header />
                    <GenresListPage />
                </Route>

                <Route exact path='/:lang(pl|en)/gatunki/:id'>
                    <Header />
                    <GenresPage />
                </Route>

                <Route path='/:lang(pl|en)/osoba/:id'>
                    <Header />
                    <ComposerPage />
                </Route>

                <Route exact path='/:lang(pl|en)/o-projekcie'>
                    <Header />
                    <AboutPage />
                </Route>

                <Route exact path='/:lang(pl|en)/artykuly'>
                    <Header />
                    <ArticlesListPage />
                </Route>

                <Route exact path='/:lang(pl|en)/artykuly/:id'>
                    <Header />
                    <ArticlePage />
                </Route>

                <Route exact path='/:lang(pl|en)/deklaracja-dostepnosci'>
                    <Header />
                    <DeclarationPage />
                </Route>

                <Route exact path={`*`}>
                    <Redirect to="/pl" />
                </Route>
            </Switch>
        </Suspense>
    );
};


function Builder() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default Builder;